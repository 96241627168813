

.card-child {
    display: none;
}

.card-container:hover .card-child {
    display: flex;
}

.list-child {
    display: none;
}

.list-container:hover .list-child {
    display: flex;
}

.checklist-child {
    display: none;
}

.checklist-container:hover .checklist-child {
    display: flex;
}

.resize-when-focussed{
    resize: none;
}
.resize-when-focussed:focus {
    resize: vertical;
}

/*




//yysource.com/2011/03/debugging-with-css-outline-all-elements/

                      * { outline: 2px dotted red }
* * { outline: 2px dotted green }
* * * { outline: 2px dotted orange }
* * * * { outline: 2px dotted blue }
* * * * * { outline: 1px solid red }
* * * * * * { outline: 1px solid green }
* * * * * * * { outline: 1px solid orange }
* * * * * * * * { outline: 1px solid blue }


http://www.marcofolio.net/css/css_trick_debug_your_html_and_css.html

       * *:hover { border:2px solid #89A81E }                   !* Solid Green *!
* * *:hover { border:2px solid #F34607 }                 !* Solid Orange *!
* * * *:hover { border:2px solid #5984C3 }               !* Solid Blue *!
* * * * *:hover { border:2px solid #CD1821 }             !* Solid Red *!
* * * * * *:hover { border:2px dotted #89A81E }          !* Dotted Green *!
* * * * * * *:hover { border:2px dotted #F34607 }        !* Dotted Orange *!
* * * * * * * *:hover { border:2px dotted #5984C3 }      !* Dotted Blue *!
* * * * * * * * *:hover { border:2px dotted #CD1821 }    !* Dotted Red *!*/
